import styled from 'styled-components';

import { PrimaryButton } from '../button';
import { Dropdown } from 'components/dropdown';
import { EXTRA_LARGE_VIEWPORT } from 'modules/theme/config';

export const UserNameContainer = styled.span`
  margin: 0 16px;
  font-size: 18px;
  white-space: pre;
  align-items: center;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.darkGrey};
  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 4px;
    fill: ${({ theme }) => theme.colors.darkGrey};
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    font-size: 14px;
  }
`;

export const Name = styled.span`
  font-weight: bold;
`;

export const UnauthorizedContainer = styled.div`
  font-size: 16px;
  font-weight: 500;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0 32px 0 16px;
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    font-size: 14px;
    a {
      margin: 0 16px 0 8px;
    }
  }
`;

export const SignUpButton = styled(PrimaryButton)`
  &.ant-btn.ant-btn-primary {
    border-radius: 12px;
    padding: 8px 16px;
    height: 40px;

    @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
      height: 32px;
      font-size: 14px;
    }
  }
`;

export const ProfileDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    padding: 12px;
    border-radius: 6px;
    min-width: 200px;
    svg {
      height: 20px;
      margin-right: 12px;
    }
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    font-size: 16px;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    .ant-dropdown-menu {
      padding: 8px;
      svg {
        height: 16px;
        margin-right: 8px;
      }
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      font-size: 14px;
    }

    .ant-dropdown-menu-title-content {
      padding: 4px 0;
    }
  }
`;

